.activity-chart {
  .header {
    color: var(--color-text-soft);
    .time {
      font-size: 0.6rem;
    }
    .name {
      font-size: 1.4rem;
    }

    // Separator line
    --bottom-space: calc(var(--spacing_0) / 2);
    padding-bottom: var(--bottom-space);
    margin-bottom: var(--bottom-space);
    border-bottom: 1px solid var(--color-background_1);
  }

  // display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}

.custom-recharts-tooltip {
  padding: calc(var(--spacing_0) / 2);
  border-radius: var(--border-radius);
  background-color: var(--color-background_1);
  color: var(--color-text-soft);
}
