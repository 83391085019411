table.summary-table {
  .logo {
    width: 2rem;
    padding: calc(var(--spacing_0) / 3) var(--spacing_0);
  }

  $breakpoint: 33rem;
  $registry-dnp: #76cfb8;
  $registry-public: #a376cf;
  $registry-unknown: #a1a1a1;

  img {
    // Add some extra room to grow
    max-width: 120%;
    max-height: 120%;
  }

  .last-update {
    .ago {
      color: var(--color-text-soft);
      font-size: 80%;
    }
  }
  .light {
    color: var(--color-text-soft);
    font-size: 80%;
  }

  .registry-badge {
    span {
      padding: 6px 10px;
      border-radius: 8px;
      color: black;
      background-color: $registry-unknown;
      &.dnp {
        background-color: $registry-dnp;
      }
      &.public {
        background-color: $registry-public;
      }
    }
    &.small {
      span {
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 80%;
      }
    }
  }

  .registry-under-name {
    display: none;
    margin-top: 3px;
  }

  // Hide unnecesary columns on small screens
  @media (max-width: $breakpoint) {
    .logo,
    .registry {
      display: none;
    }
    .registry-under-name {
      display: block;
    }
  }
}
