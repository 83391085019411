:root {
  // Colors
  // Background
  --color-background_3: #363b40; // Card level 2
  --color-background_2: #2c3034; // Card level 2
  --color-background_15: #2a2e32; // Card level 1.5
  --color-background_1: #282c30; // Card level 1
  --color-background_0: #202326; // Lowest level of the page
  --color-background_-1: #1b1d20; // Darker color for hero section
  // Test
  --color-text-main: #ffffff;
  --color-text-soft: #767e86;
  // Brand
  --color-brand_0: #2fbcb2;

  // Spacing
  --spacing_0: 1rem;
  --border-radius: 8px;

  // Sizes
  h1 {
    margin: 0;
  }
}

// Layout

@mixin layout-container {
  max-width: 52rem;
  margin: 0 auto;
  padding: var(--spacing_0);
}

.app-body {
  @include layout-container;

  margin-top: 2rem;
  > *:not(:last-child) {
    margin-bottom: calc(2 * var(--spacing_0));
  }
}

.app-container {
  @include layout-container;
}

// Component styles

.card {
  background-color: var(--color-background_1);
  padding: var(--spacing_0);
  border-radius: var(--border-radius);
}

.table-container {
  // Responsive table on small screens
  overflow-x: auto;
  // Nice rounded borders
  border-radius: var(--border-radius);
}
table {
  // Auto-size
  table-layout: auto;
  width: 100%;
  // To not show an ugly little edge
  border-collapse: collapse;

  th,
  td {
    padding: var(--spacing_0);
    text-align: left;
  }

  tr {
    transition: background-color ease 150ms;
    background-color: var(--color-background_1);
  }
  thead {
    tr {
      background-color: var(--color-background_15);
    }
    th {
      color: var(--color-text-soft);
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: var(--color-background_15);
    }
    tr:hover {
      background-color: var(--color-background_3);
    }
  }
}

// Base styles

body {
  background-color: var(--color-background_0);
  color: var(--color-text-main);
}

a {
  color: #2c7be5;
  text-decoration: none;
}

.soft {
  color: var(--color-text-soft);
}
